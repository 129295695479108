import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/employee'
        icon='/media/icons/duotune/general/gen002.svg'
        title='My Employee'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div> */}
      {/* **************************************************************************************** */}

      <AsideMenuItemWithSub
        to='/config'
        title='Configuration'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      >
        <AsideMenuItem to='/config/sap-config' title='SAP Configuration' hasBullet={true} />
        <AsideMenuItem to='/config/email-config' title='Email Configuration' hasBullet={true} />
        <AsideMenuItem to='/config/plant-config' title='Plant Master' hasBullet={true} />
        <AsideMenuItem to='/config/process-config' title='Process Master' hasBullet={true} />
        <AsideMenuItem
          to='/config/process-check-config'
          title='Process Check Master'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title='Master Page'
        to='/master'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      >
        <AsideMenuItem to='/master/user' title='User Master' hasBullet={true} />
        <AsideMenuItem to='/master/machine' title='Machine Master' hasBullet={true} />
        <AsideMenuItem to='/master/part' title='Part Master' hasBullet={true} />
        <AsideMenuItem to='/master/item-mst' title='Item Master' hasBullet={true} />
        <AsideMenuItem to='/master/process-bom' title='Process BOM' hasBullet={true} />
        <AsideMenuItem to='/master/production-bom' title='Production BOM' hasBullet={true} />
        <AsideMenuItem to='/master/spec-part' title='Specification Master' hasBullet={true} />
        <AsideMenuItem to='/master/location' title='Storage Location' hasBullet={true} />
        <AsideMenuItem
          to='/master/return-reason-remark'
          title='Return Reason Remark'
          hasBullet={true}
        />
        <AsideMenuItem to='/master/foil-type' title='Foil Type' hasBullet={true} />
        <AsideMenuItem to='/master/type-pkg' title='Type Of Packing' hasBullet={true} />
        <AsideMenuItem to='/master/substrate' title='Substrate' hasBullet={true} />
        <AsideMenuItem to='/master/temper' title='Temper Master' hasBullet={true} />
        <AsideMenuItem to='/master/machine-grp' title='Machine Group' hasBullet={true} />
        <AsideMenuItem to='/master/item-grp' title='Item Group' hasBullet={true} />
        <AsideMenuItem to='/master/roll-diameter' title='Roll Diameter' hasBullet={true} />
        <AsideMenuItem to='/master/core-type' title='Core Type' hasBullet={true} />
        <AsideMenuItem to='/master/part-sub-type' title='Part Sub Type' hasBullet={true} />
        <AsideMenuItem to='/master/sub-grp-mst' title='Sub Group Master' hasBullet={true} />
        <AsideMenuItem to='/master/item-cat-mst' title='Item Category Master' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/plant-planning'
        icon='/media/icons/duotune/abstract/abs039.svg'
        title='Plant Planning'
        fontIcon='bi-layers'
        dislpay={user.userLevelID <= 3 ? '' : 'd-none'} //planner & plant Head
      />

      <AsideMenuItem
        to='/daily-planning'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Daily Planning'
        fontIcon='bi-layers'
        dislpay={user.userLevelID === 4 || user.userLevelID === 1 ? '' : 'd-none'} //Supevisor & admin
      />
{/* 
      <AsideMenuItem
        to='/machine-daily-check'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Machine Daily Check'
        fontIcon='bi-layers'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      /> */}
{/* 
      <AsideMenuItem
        to='/machine-preventive'
        icon='/media/icons/duotune/general/gen017.svg'
        title='Machine Preventive'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 5 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}

      <AsideMenuItem
        to='/machine-part-change-req'
        icon='/media/icons/duotune/general/gen054.svg'
        title='Machine Part Change Request'
        fontIcon='bi-layers'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      />
      {/* =====================Reports============================ */}
      <AsideMenuItemWithSub
        title='Reports'
        to='/reports'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      >
        <AsideMenuItem to='/reports/item-batch' title='Item Batch' hasBullet={true} />
        <AsideMenuItem to='/reports/sales-orders' title='Sales Orders' hasBullet={true} />

        {/* <AsideMenuItem to='/master/department' title='Department' hasBullet={true} /> */}
      </AsideMenuItemWithSub>
      {/* =========================== ArtWork ======================================== */}
      <AsideMenuItemWithSub
        title='Art Work'
        to='/artwork'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
        dislpay={user.userLevelID === 1 ? '' : 'd-none'} // Admin
      >
        <AsideMenuItem to='/artwork/cylinder-making' title='Cylinder Making' hasBullet={true} />
        <AsideMenuItem to='/artwork/cylinder-receive' title='Cylinder Received' hasBullet={true} />
        <AsideMenuItem
          to='/artwork/sampling-done'
          title='Sampling Done (Description Of Product)'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/artwork/sales-cylinder-recv'
          title='Sales Po Cylinder Recovery'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/artwork/cylinder-destruction'
          title='Cylinder Destruction'
          hasBullet={true}
        />

        {/* <AsideMenuItem to='/master/department' title='Department' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/my-jobcard'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='My JobCard'
        fontIcon='bi-layers'
        dislpay={user.userLevelID === 5 || user.userLevelID === 1 ? '' : 'd-none'} // Opertaor
      />
      {/* <AsideMenuItem
        to='/online-qc'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Process Parameter(QC1)'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 4 ? '' : 'd-none'} // Opertaor
      /> */}
      <AsideMenuItem
        to='/after-qc2'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Specification Sheet(QC2)'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 4 ? '' : 'd-none'} // Opertaor
      />
      <AsideMenuItem
        to='/roll-maintenance'
        icon='/media/icons/duotune/general/gen031.svg'
        title='Roll Maintenance'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 5 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />

      {/* ======================== Stock Transfer =================================== */}
      <AsideMenuItem
        to='/stock-transfer'
        icon='/media/icons/duotune/general/gen017.svg'
        title='Stock Transfer'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />

      {/* ======================== Part Repairing =================================== */}
      <AsideMenuItem
        to='/part-repairing'
        icon='/media/icons/duotune/abstract/abs039.svg'
        title='Part Repairing'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />
      {/* ======================== material Inward =================================== */}
      <AsideMenuItem
        to='/material-inward'
        icon='/media/icons/duotune/abstract/abs039.svg'
        title='Material Inward'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />
      {/* ======================== Material Out =================================== */}
      <AsideMenuItem
        to='/material-outward'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Material Outward'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />

      {/* ======================== Cylinder =================================== */}
      {/* <AsideMenuItem
        to='/Cylinder'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Cylinder'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}
      {/* ======================== Cylinder destruction=================================== */}
      {/* <AsideMenuItem
        to='/cylinder-destruction'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Cylinder Destruction'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}

      {/* ======================== Art Work =================================== */}
      {/* <AsideMenuItem
        to='/art-work'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Art Work'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}

      {/* ======================== Job Work In =================================== */}
      <AsideMenuItem
        to='/job-work-in'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Job Work IN'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />

      {/* ======================== JobWork Out =================================== */}
      <AsideMenuItem
        to='/job-work-out'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Job Work Out'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />
      {/* ======================== Bom Return =================================== */}
      {/* <AsideMenuItem
        to='/bom-return'
        icon='/media/icons/duotune/abstract/abs037.svg'
        title='Bom Return'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}

      {/* ======================== Customer Complain=================================== */}
      {/* <AsideMenuItem
        to='/Customer-Complain'
        icon='/media/icons/duotune/abstract/abs037.svg'
        title='Customer Complain'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      /> */}
      {/* ********************************************************************************************* */}

      <AsideMenuItem
        to='/customer-complain'
        icon='/media/icons/duotune/abstract/abs032.svg'
        title='Customer Complain'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'}
      />
      {/* ======================== Vender Complain =================================== */}
      <AsideMenuItem
        to='/vendor-complain'
        icon='/media/icons/duotune/abstract/abs038.svg'
        title='Vendor Complain'
        fontIcon='bi-layers'
        dislpay={user.departmentID === 6 || user.userLevelID === 1 ? '' : 'd-none'} // PM
      />

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
